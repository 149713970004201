<template>
    <div>
        <ipo-loader v-if="showLoader" class="with-margins"/>
        <div v-else class="academies-list">
            <b-row>
                <b-col>
                    <pcg-btn size="small" style="margin-bottom: 2rem; margin-top: 1rem;" @click="createInstitution" :disabled="createSpinner">
                      {{ $t('views.institutions.list.new') }}
                      <spinner v-if="createSpinner"/>
                    </pcg-btn>
                </b-col>
                <b-modal ref="institutionModal"
                         :hide-header="true"
                         :centered="true"
                         footer-class="my-modal-footer"
                         v-bind:ok-title="$t('general.confirm')"
                         ok-variant="primary"
                         :no-close-on-esc="true"
                         @ok="institutionSave"
                         v-bind:cancel-title="$t('general.cancel')"
                         cancel-variant="default"
                         content-class="my-modal-content"
                >
                  <b-col class="type-checkbox">
                    <pcg-radio
                        name="installationVersion"
                        v-model="rInstitution.installationVersion"
                        :options="installationOptions"
                        :show-label="true"
                        :label="$t('views.institutions.list.fields.installation_version')"
                    />
                  </b-col>
                    <pcg-text-input class="my-input" v-model="rInstitution.name" :label="$t('views.institutions.list.fields.name')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.subDomain" :label="$t('views.institutions.list.fields.sub_domain')" :showLabel="true"/>
                    <pcg-text-input class="my-input" type="email" v-model="rInstitution.managerEmail" :label="$t('views.institutions.list.fields.manager_email')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.tenantId" :label="$t('views.institutions.list.fields.identifier')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.secret" :label="$t('views.institutions.list.fields.secret')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.origin" :label="$t('views.institutions.list.fields.origin')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.issuer" :label="$t('views.institutions.list.fields.issuer')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.endSessionEndpoint" :label="$t('views.institutions.list.fields.end_session_endpoint')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.authorizationEndpoint" :label="$t('views.institutions.list.fields.authorization_endpoint')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.tokenEndpoint" :label="$t('views.institutions.list.fields.token_endpoint')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.userInfoEndpoint" :label="$t('views.institutions.list.fields.user_info_endpoint')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rInstitution.scope" :label="$t('views.institutions.list.fields.scope')" :showLabel="true"/>
                </b-modal>
            </b-row>
            <div class="academies-elements" v-if="institutions.length > 0">
                <b-row class="text-label list-header">
                    <b-col class="th">
                        {{ $t('views.institutions.list.name') }}
                    </b-col>
                  <b-col class="th">
                    {{ $t('views.institutions.list.contract_end_date') }}
                  </b-col>
                  <b-col class="th">
                    {{ $t('views.institutions.list.polon_version') }}
                  </b-col>
                    <b-col cols='1' class="pub-type th">
                        {{ $t('views.institutions.list.locked') }}
                    </b-col>
                    <b-col cols='4' class="buttons th">
                        &nbsp;
                    </b-col>
                </b-row>
                <div v-for="institution in institutions" :key="institution.id" class="academy">
                    <institution-element :institution="institution" @updated="refreshList"/>
                </div>
            </div>
            <pcg-box v-else>
                <div class="d-flex justify-content-center no-records">{{ $t('views.institutions.list.no_records') }}</div>
            </pcg-box>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import waitForJobs from '../../mixins/wait_for_jobs'
import ApiInstitutions from '../../api/institutions'
import IpoLoader from '../../components/IpoLoader'
import InstitutionElement from '../../components/InstitutionElement'
import loaderDelay from '../../mixins/loader_delay'
import Spinner from '../../components/Spinner'
export default {
  name: 'institutions-list',
    mixins: [clickaway, loaderDelay, waitForJobs],
  components: { IpoLoader, InstitutionElement, Spinner },
  data: function () {
    return {
      institutions: [],
      createSpinner: false,
      rInstitution: {
        aid: null,
        name: null,
        subDomain: null,
        tenantId: null,
        managerEmail: null,
        secret: null,
        origin: null,
        issuer: null,
        endSessionEndpoint: null,
        authorizationEndpoint: null,
        tokenEndpoint: null,
        userInfoEndpoint: null,
        scope: null,
        installationVersion: '0'
      },
      installationOptions: [
        {
          text: this.$t('views.institutions.list.installation_versions.basic'), value: '0'
        },
        {
          text: this.$t('views.institutions.list.installation_versions.extended'), value: '1'
        },
      ]
    }
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.institutions.list.title'),
      subtitle: this.$t('views.institutions.list.subtitle'),
      capitalizeSubtitle: false
    })
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole'])
  },
  created () {
    this.getInstitutions()
  },
   methods: {
    getInstitutions () {
      ApiInstitutions.getInstitutions()
        .then(result => {
          if (result) {
            this.institutions = result.data.datas
            if (this.currentRole === 'systemadministrator' || this.currentRole === 'systemmanager') {
              this.loadingDone = true
            } else {
              if (this.institutions.length > 1) {
                this.$router.go(0)
              } else {
                this.loadingDone = true
              }
            }
          } else {
            // result jest false gdy brak uprawień
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
          }
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
            this.loadingDone = true
          })
    },
    createInstitution () {
      this.$refs.institutionModal.show()
    },
    escModal () {
      this.$refs.institutionModal.hide()
    },
    institutionSave (e) {
      e.preventDefault()
      this.createSpinner = true
      ApiInstitutions.createInstitution(this.rInstitution)
        .then(result => {
          this.$refs.institutionModal.hide()
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.updateInstitution, this.updateInstitution)
          } else {
            this.createSpinner = false
          }
        })
        .catch(err => {
          // console.log(err)
          this.$refs.institutionModal.show()
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            let val = key
            if (key.toString() == "base"){
              val = ""
            }
            message.push(`${val.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          this.createSpinner = false
        })
    },
    refreshList () {
      this.getInstitutions()
    },
    updateInstitution () {
      ApiInstitutions.restartApp()
        .then(result => {
          this.createSpinner = false
          this.$toastr.s(this.$t('views.institutions.created'))
          for (const key in this.rInstitution) {
            this.rInstitution[key] = null
          }
          setTimeout(() => {
            this.$router.go(0)
          }, 1000)
        })
        .catch(error => {
          const datas = error.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          this.createSpinner = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    .text-label {
        font-weight: bold;
    }

    .my-input {
        :deep(.pcg-input) {
            height: calc(1.5rem + 0.75rem + 2px)
        }
        :deep(.pcg-input-label) {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
    }

    .academies-elements {
      margin-bottom: 2rem;
      margin-top: 1rem;
      background-color: $pcg-white;
      border-radius: 15px;
      min-width: 625px;
      .list-header {
        background-color: $main-color;
        color: $pcg-white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        .th {
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          vertical-align: middle;
        }

        .th:first-child {
          padding-left: 15px;
          border-top-left-radius: 15px;
        }

        .th:last-child {
          padding-right: 15px;
          border-top-right-radius: 15px;
        }
      }
    }

    .academy {
      //border-bottom: 1px solid $pcg-lightest-gray;
      margin-right: -10px;
      margin-left: -10px;
      padding-top: 10px;
      background-color: $pcg-white;
      :deep(.academy) {
        background-color: $pcg-white;
        &.row {
          margin-right: 0;
          margin-left: 0;
          //height: 52px;
        }
      }

      &:last-child {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        :deep(.academy) {
          background-color: $pcg-white;
          &.row {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            .col {
              padding-bottom: 15px;
            }

            .col:first-child {
              border-bottom-left-radius: 15px;
            }

            .col:last-child {
              border-bottom-right-radius: 15px;
            }
          }
        }
      }
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
      .my-input {
        :deep(.pcg-input-label) {
          color: $pcg-gray;
        }
      }
      .academies-elements {
        background-color: $pcg-white;
        .list-header {
          background-color: $main-color;
          color: $pcg-white;
        }
      }

      .academy {
        background-color: $pcg-white;
        :deep(.academy) {
          background-color: $pcg-white;
        }

        &:last-child {
          :deep(.academy) {
            background-color: $pcg-white;
          }
        }
      }
    }
</style>
import Auth from '../../api/auth'
import Cookies from 'js-cookie'
import { decamelize } from '@ridi/object-case-converter'
import Vue from 'vue'

const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'ipAT'
const LOCAL_STORAGE_REDIRECT_TO_KEY = 'ipRT'
const LOCAL_STORAGE_USER_ID_KEY = 'ipUI'
const LOCAL_STORAGE_USER_FIRST_NAME_KEY = 'ipFN'
const LOCAL_STORAGE_USER_LAST_NAME_KEY = 'ipLN'
const LOCAL_STORAGE_USER_ROLES_KEY = 'ipRS'
const LOCAL_STORAGE_USER_CURRENT_ROLE_KEY = 'ipCR'
const LOCAL_STORAGE_USER_AVATAR_KEY = 'ipAV'
const LOCAL_STORAGE_EXPIRE = 'ipExp'
const LOCAL_STORAGE_CONTRAST = 'ipCon'
const LOCAL_STORAGE_SESSION_ENDPOINT = 'ipSE'

const auth = {
  namespaced: true,
  state: {
    id: localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || null,
    token: localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY) || null,
    redirectTo: JSON.parse(localStorage.getItem(LOCAL_STORAGE_REDIRECT_TO_KEY)) || null,
    firstName: localStorage.getItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY) || null,
    lastName: localStorage.getItem(LOCAL_STORAGE_USER_LAST_NAME_KEY) || null,
    roles: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_ROLES_KEY)) || null,
    currentRole: localStorage.getItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY) || null,
    avatar: localStorage.getItem(LOCAL_STORAGE_USER_AVATAR_KEY) || null,
    expire: localStorage.getItem(LOCAL_STORAGE_EXPIRE) || new Date().getTime() - 1000,
    contrast: localStorage.getItem(LOCAL_STORAGE_CONTRAST) || null,
    signingOut: false,
    sessionEndpoint: localStorage.getItem(LOCAL_STORAGE_SESSION_ENDPOINT) || null,
  },
  getters: {
    signingOut: state => state.signingOut,
    signedIn: state => {
      if (process.env.RAILS_ENV !== 'development' && process.env.LOCAL_LOGIN != '1') {
        const zm = Vue.$cookies.get('token')
        if (zm) {
          return true
        } else {
          return !!state.id
        }
      } else {
        return !!state.id
      }
    },
    getRedirectTo: state => state.redirectTo,
    roles: state => state.roles,
    currentRole: state => state.currentRole,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    fullName: state => `${state.firstName} ${state.lastName}`,
    avatar: state => state.avatar,
    userId: state => state.userId,
    expire: state => { return state.expire < new Date().getTime() },
    contrast: state => state.contrast,
    sessionEndpoint: state => state.sessionEndpoint
  },
  mutations: {
    setSigningOut (state, value) {
      state.signingOut = value
    },
    setRedirectTo(state, redirectTo) {
      state.redirectTo = redirectTo
    },
    setUser(state, payload) {
      state.roles = payload.roles
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      payload.avatar ? state.avatar = payload.avatar.url : state.avatar = null
      state.userId = payload.userId
      state.expire = new Date(payload.expiresAt).getTime()
      state.sessionEndpoint = payload.sessionEndpoint
    },
    setCurrentRole (state, role) {
      state.currentRole = role
    },
    updateState (state) {
      state.id = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || null
      state.redirectTo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_REDIRECT_TO_KEY)) || null
      state.firstName = localStorage.getItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY) || null
      state.lastName = localStorage.getItem(LOCAL_STORAGE_USER_LAST_NAME_KEY) || null
      state.roles = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_ROLES_KEY)) || null
      state.currentRole = localStorage.getItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY) || null
      state.avatar = localStorage.getItem(LOCAL_STORAGE_USER_AVATAR_KEY) || null
      state.expire = localStorage.getItem(LOCAL_STORAGE_EXPIRE) || new Date().getTime() - 1000
      state.contrast = localStorage.getItem(LOCAL_STORAGE_CONTRAST) || null
      state.sessionEndpoint = localStorage.getItem(LOCAL_STORAGE_SESSION_ENDPOINT) || null
    }
  },
  actions: {
    checkUser ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        Auth.fetchUser()
          .then(response => {
            if (response && response.data && response.data.data) {
              setItems(response)
              commit('setUser', response.data.data.attributes)
              resolve(response)
            }
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            reject(error)
          })
      })
    },
    fetchUser ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        Auth.fetchUser()
          .then(response => {
            if (response && response.data && response.data.data) {
              setItems(response)
              commit('setUser', response.data.data.attributes)
              resolve(response)
            }
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            reject(error)
          })
      })
    },
    signIn ({ commit, dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        Auth.signIn(params)
          .then(response => {
            if (response) {
              console.log(response.data)
              // const authToken = response.headers.authorization
              if (response.data.location) {
                window.location = response.data.location
              } else {
                setItems(response)
                commit('setUser', response.data.data.attributes)
                if (state.currentRole !== response.data.data.attributes.currentRole) {
                  if (state.currentRole == null) {
                    localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, response.data.data.attributes.currentRole)
                    dispatch('setCurrentRole', state.currentRole)
                  } else {
                    dispatch('setCurrentRole', state.currentRole)
                    Auth.setCurrentRole(state.currentRole)
                      .then(response => {
                        this.$nextTick(() => {
                          window.location = '/'
                        })
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  }
                }
                Cookies.set('dnOpenIdSessionState', params.session_state)
              }
              resolve(response)
            } else {
              const error = {
                response: {
                  data: {
                    error: 'Brak uprawnień do logowania'
                  }
                }
              }
              removeItems()
              commit('setUser', {})
              reject(error)
            }
          })
          .catch(error => {
            console.log('signIn error')
            console.log(error.response)
            removeItems()
            commit('setUser', {})
            reject(error)
          })
      })
    },
    devSignIn ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        Auth.devSignIn(payload)
          .then(response => {
            setItems(response)
            commit('setUser', response.data.data.attributes)
            dispatch('setCurrentRole', state.currentRole)
            resolve(response)
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            reject(error)
          })
      })
    },
    redirSignIn ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        Auth.redirSignIn(payload)
          .then(response => {
            setItems(response)
            commit('setUser', response.data.data.attributes)
            if (state.currentRole !== response.data.data.attributes.currentRole) {
              if (state.currentRole == null) {
                localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, response.data.data.attributes.currentRole)
                dispatch('setCurrentRole', state.currentRole)
              } else {
                dispatch('setCurrentRole', state.currentRole)
                Auth.setCurrentRole(state.currentRole)
                  .then(response => {
                    this.$nextTick(() => {
                      window.location = '/'
                    })
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
            } else {
            }
            resolve(response)
          })
          .catch(error => {
            console.log('redirSignIn error')
            console.log(error)
            removeItems()
            commit('setUser', {})
            reject(error)
          })
      })
    },
    setSigninOut ({ commit, dispatch }, value) {
      commit('setSigningOut', value)
    },
    signOut ({ commit, dispatch }) {
      commit('setSigningOut', true)
      return new Promise((resolve, reject) => {
        Auth.signOut()
          .then(response => {
            removeItems()
            commit('setUser', {})
            Cookies.remove('dnOpenIdSessionState')
            Cookies.remove('token')
            resolve(response)
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            reject(error)
          })
      })
    },
    setCurrentRole ({ state, commit, dispatch }, role) {
      if (Array.isArray(state.roles) && state.roles.length > 0) {
        if (state.roles.includes(role)) {
          localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, role)
          commit('setCurrentRole', role)
        } else {
          localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, state.roles[0])
          commit('setCurrentRole', state.roles[0])
        }
      }
    },
    setRedirectTo ({ commit }, payload) {
      commit('setRedirectTo', payload)
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_TO_KEY, JSON.stringify(payload))
    },
    clearRedirectTo ({ commit }) {
      commit('setRedirectTo', null)
      localStorage.removeItem(LOCAL_STORAGE_REDIRECT_TO_KEY)
    },
    updateState ({ commit }) {
      commit('updateState')
    }
  }
}

function setItems (response) {
  localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, response.data.data.attributes.id)
  localStorage.setItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY, response.data.data.attributes.firstName)
  localStorage.setItem(LOCAL_STORAGE_USER_LAST_NAME_KEY, response.data.data.attributes.lastName)
  localStorage.setItem(LOCAL_STORAGE_USER_ROLES_KEY, JSON.stringify(response.data.data.attributes.roles))
  if (response.data.data.attributes.avatar) {
    localStorage.setItem(LOCAL_STORAGE_USER_AVATAR_KEY, response.data.data.attributes.avatar.url)
  }
  localStorage.setItem(LOCAL_STORAGE_EXPIRE, new Date(response.data.data.attributes.expiresAt).getTime())
  localStorage.setItem(LOCAL_STORAGE_SESSION_ENDPOINT, response.data.data.attributes.sessionEndpoint)
}

function removeItems () {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_LAST_NAME_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_AVATAR_KEY)
  localStorage.removeItem(LOCAL_STORAGE_EXPIRE)
  localStorage.removeItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY)
  localStorage.removeItem(LOCAL_STORAGE_SESSION_ENDPOINT)
}

export {
  auth
}

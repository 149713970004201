<template>
  <div class='users-list'>
    <ipo-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="filters" style="margin-bottom: .5rem">
          <b-row>
            <h2 class="sc-title">{{ $t('views.students.list.students_list') }}</h2>
            <span class="sort-by-text">{{ $t('views.users.list.sort_by') }}</span>
            <pcg-sort-btn
              class="sort-by my-sort"
              :options="sortOptions"
              v-model="sortBy"
              @input="getFilteredUsers"
            />
          </b-row>
          <b-row align-v="center" style="margin-top: 1.5rem; margin-bottom: 2rem;" class="normal">
            <b-col cols="6">
              <label for="fullname" class="filter-users">{{ $t('views.users.list.filter_users') }}</label>
              <pcg-text-input
                  id="fullname"
                  class="my-text-input"
                  style="margin-right: 3rem"
                  :show-label="false"
                  :placeholder="$t('views.users.list.by_names')"
                  v-model="filters.fullName"
                  @input="getFilteredUsers"
              />
            </b-col>
            <b-col cols="6">
              <label for="status" class="filter-users">{{ $t('views.users.list.fields.status') }}</label>
              <pcg-select
                  id="status"
                  class="my-select"
                  style="margin-right: 2rem"
                  :options="tDictionary(statusOptions)"
                  :default-option="{ text: $t('general.select'), id: '' }"
                  v-model="filters.status"
                  @input="getFilteredUsers"
              />
            </b-col>
          </b-row>
        </div>
      <b-table v-if="students.length > 0"
            class="users-table"
            borderless hover
            thead-class="sthead"
            tbody-tr-class="strow"
            :fields="fields"
            :items="students"
            :primary-key="students.id"

      >
        <template v-slot:cell(pesel)="data">
          <div class="my-text">
           {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(documentNumber)="data">
          <div class="my-text">
           {{ data.value.toUpperCase() }}
          </div>
        </template>
        <template v-slot:cell(documentType)="data">
          <div class="my-text">
           {{ data.value || '' }}
          </div>
        </template>
        <template v-slot:cell(surname)="data">
          <div class="my-text">
            {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(name)="data">
          <div class="my-text">
           {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(otherNames)="data">
          <div class="my-text">
            {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(lastExportDate)="data">
          <div class="my-text">
            <span v-if="data.value">{{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}</span>
          </div>
        </template>
        <template v-slot:cell(status)="data">
          <student-status :status="data.value" />
        </template>
        <template v-slot:cell(buttons)="data">
          <div class='import-button-group__row'>
            <pcg-btn size="small" variant="additional" @click="showHistory(data.item.id)">
              <i class="far fa-comment-alt" style='margin-right: 1rem;'/>
              {{ $t('views.students.list.show_history') }}
            </pcg-btn>
            <pcg-btn size="small" variant="additional" @click="showErrors(data.item.errors)">
              <i class="far fa-comment-alt" style='margin-right: 1rem;'/>
              {{ $t('views.students.list.show_errors') }}
            </pcg-btn>
            <student-options-select
                :options="['show_json', 'download_json', 'show_polon_json', 'download_polon_json']"
                @show_json="showStudentJson(data.item.id)"
                @download_json="downloadStudentJson(data.item.id)"
                @show_polon_json="showStudentPolonJson(data.item.id)"
                @download_polon_json="downloadStudentPolonJson(data.item.id)"

            />
          </div>
        </template>
      </b-table>
      <pcg-box v-else style="margin-top: 1rem">
        <div class="d-flex justify-content-center no-records">{{ $t('views.users.list.no_records') }}</div>
      </pcg-box>
      <b-modal ref="show_json"
        class="download-json"
        :hide-header="true"
        :centered="true"
        ok-variant="primary"
        :ok-only="true"
        :no-close-on-esc="false"
        content-class="my-modal-content"
        footer-class="my-modal-footer"
      >
        <json-prettify
        :json="currentStudent.json" />
      </b-modal>
      <b-modal ref="show_polon_json"
               class="download-json"
               :hide-header="true"
               :centered="true"
               ok-variant="primary"
               :ok-only="true"
               :no-close-on-esc="false"
               content-class="my-modal-content"
               footer-class="my-modal-footer"
      >
        <json-prettify
            :json="currentStudent.polonJson" />
      </b-modal>
      <b-modal ref="show_error"
        class="import-errors"
        :title-html="$t('views.students.list.record_errors')"
        dialog-class="show-student-changes"
        :centered="true"
        ok-variant="primary"
        :ok-only="true"
        :no-close-on-esc="false"
        content-class="my-modal-content"
        footer-class="my-modal-footer"
      > 
        <div>
          <student-error
          :student-errors="currentStudent.errors"
          />
        </div>
      </b-modal>
      <b-modal ref="show_history"
        class="show-student-json"
        dialog-class="show-student-changes"
        :centered="true"
        ok-variant="primary"
        :ok-only="true"
        scrollable
        :title-html="$t('views.students.list.record_history')"
        :no-close-on-esc="false"
        @hide="closeHistoryModal()"
        content-class="my-modal-content student-modal"
        footer-class="my-modal-footer"
      >
      <div v-if="history === null">
        <ipo-loader/>
      </div>
      <div v-else>
        <student-history
        :student-history="history"
        />
      </div>
      </b-modal>
      <nav :aria-label="$t('general.pagination')">
        <!-- tymczasowy json -->
        <pcg-pagination
            class="d-flex justify-content-center my-pagination"
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="changePage"
        ></pcg-pagination>
      </nav>
    </div>
  </div>
</template>

<script>

import ApiStudents from '../../api/students'
import NewUserModal from '../../components/NewUserModal'
import translateDictionary from '../../mixins/translate_dictionary'
import IpoLoader from '../../components/IpoLoader'
import ImportStatus from '../../components/importStatus'
import StudentOptionsSelect from '../../components/StudentOptionsSelect'
import StudentStatus from '../../components/StudentStatus'
import loaderDelay from '../../mixins/loader_delay'
import { mapGetters } from 'vuex'
import JsonPrettify from '../../components/JsonPrettify'
import waitForJobs from '../../mixins/wait_for_jobs'
import StudentHistory from '../../components/StudentHistory'
import DownloadJson from '../../mixins/download_json'
import StudentError from '../../components/StudentError'
import InstitutionsApi from "../../api/institutions";

export default {
  name: 'students-list',
  mixins: [loaderDelay,translateDictionary, waitForJobs, DownloadJson],
  components: {
    NewUserModal,
    IpoLoader,
    ImportStatus,
    StudentStatus,
    StudentOptionsSelect,
    JsonPrettify,
    StudentHistory,
    StudentError
  },
  data: function () {
    return {
      jobId: null,
      students: [],
      history: null,
      filters: {
        orderBy: 'full_name',
        orderDirection: 'asc',
        user: '',
        importId: null,
        status: null
      },
      // do zamiany z konkretnymi JSONami z backendu
      currentStudent: {
        json: {},
        polonJson: {},
        errors: {}
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      checkedImports: [],
      selectedAllImports: false,
      perPage: 20,
      sortOptions: [],
      errors: [],
      userErrors: [],
      statusOptions: [
        { text: 'Wybierz', text_en: 'Choose', id: '' },
        { text: 'Wysłany', text_en: 'Sent', id: '0' },
        { text: 'Wyeksportowano część', text_en: 'Partially sent', id: '1' },
        { text: 'Niewysłany', text_en: 'Sent error', id: '2' }
      ],
      installationVersion: 0
    }
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.students.list.title'),
      subtitle: this.$t('views.users.list.subtitle'),
      capitalizeSubtitle: false
    })
    this.buildSortOptions()
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildSortOptions()
      }
    }
  },
  created () {
    InstitutionsApi.getInstitutionInformation()
        .then(response => {
          this.installationVersion = response.data.installationVersion
          if (this.installationVersion !== 1) {
            this.$toastr.i(this.$t('general.no_rights'))
            this.$router.push({ name: 'imports_list_path' })
          }
        })
    ApiStudents.getStudents(this.$route.query)
      .then(result => {
        this.students = result.data.datas
        this.pagination = result.data.pagination
        const query = this.$route.query
        this.perPage = this.pagination.items || 10
        if (!this.checkPerPage()) {
          this.perPage = 10
        }    
        if (Object.prototype.hasOwnProperty.call(query, 'orderBy')) {
          const sort = this.sortBy
          sort.orderBy = query.orderBy
          this.sortBy = sort
        }
        this.filters.importId = this.$route.query.importId
      }
    ).finally(() => {
      this.loadingDone = true
    })
  },
  methods: {
    buildSortOptions () {
      this.sortOptions = [
        {
          text: this.$t('views.students.list.fields.album_number'),
          value: 'album_number'
        },
        {
          text: this.$t('views.students.list.fields.surname'),
          value: 'full_name'
        },
        {
          text: this.$t('views.students.list.fields.document_type'),
          value: 'document_type'
        },
      ]
    },
    showStudentJson(id){
      this.currentStudent.json = {}
      ApiStudents.getStudentJson(id)
      .then(result => {
        this.currentStudent.json = result.data
        }
      )
      this.$refs.show_json.show()

    },
    showStudentPolonJson(id){
      this.currentStudent.polonJson = {}
      ApiStudents.getStudentPolonJson(id)
          .then(result => {
                this.currentStudent.polonJson = result.data
              }
          )
      this.$refs.show_polon_json.show()

    },
    downloadStudentJson(id){
      this.currentStudent.json = {}
      ApiStudents.getStudentJson(id)
        .then(result => {
          this.downloadJson(result.data, `student_${id}`);
        }
      )
    },
    downloadStudentPolonJson(id){
      this.currentStudent.polonJson = {}
      ApiStudents.getStudentPolonJson(id)
          .then(result => {
                this.downloadJson(result.data, `student_${id}`);
              }
          )
    },
    showErrors(errors){
      this.currentStudent.errors = errors
      this.$refs.show_error.show()
    },
    showHistory(student_id){
      this.$refs.show_history.show()
      ApiStudents.initStudentHistory(student_id)
      .then(result => {
        this.jobId = result.data.bwId
        if (this.jobId !== undefined) {
          this.achievementsSpinner = true
          this.waitForBigWorker(this.jobId, this.getHistory, this.studentHistoryError)
        }
      })
      .catch((error) => {
        console.log(error)
      })
    },
    getHistory () {
      ApiStudents.getStudentHistory(this.jobId)
        .then((result) => {
          this.history = result.data
        })
        .catch((error) => {
          console.log(error)
        })
      this.achievementsSpinner = false
    },
    studentHistoryError () {
      this.achievementsSpinner = false
      this.$toastr.e(this.$t('views.histories.report_error'))
    },
    getFilteredUsers () {
      this.pagination.page = 1
      this.getStudents()
    },
    closeHistoryModal() {
      this.$refs.show_history.show()
      this.history = null
    },
    getFilters () {
      const oFilters = {}
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key]) {
          oFilters[key] = this.filters[key]
        }
      })
      return oFilters
    },
    getStudents () {
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      if (!this.checkPerPage()) {
        this.perPage = 10
      }
      query.per_page = this.perPage
      if (filters.importId) {
        query.importId = this.filters.importId
      }
      this.$router.push({ name: 'students_list_path', query: query })
      ApiStudents.getStudents(query)
        .then(result => {
          this.addUserSpinner = false
          this.students = result.data.datas
          this.resultsCount = result.data.results
          this.pagination = result.data.pagination
        })
        .catch((err) => {
          console.log(err)
        })
    },
    checkPerPage () {
      return this.perPage === 10 || this.perPage === 25 || this.perPage === 50 || this.perPage === 100
    },
    changePage () {
      this.getStudents()
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    },
    setTime (date) {
      const momentDate = moment(date)
      return momentDate.format('LT')
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole', 'expire']),
    ...mapGetters('page', ['subDomain']),
    sortBy: {
      get () {
        return { orderBy: this.filters.orderBy, orderDirection: this.filters.orderDirection === 'asc' ? 'desc' : 'asc' }
      },
      set (newValue) {
        this.filters.orderBy = newValue.orderBy
        this.filters.orderDirection = newValue.orderDirection === 'asc' ? 'desc' : 'asc'
      }
    },
    fields: function () {
      return [
        {
          key: 'pesel',
          label: this.$t('views.students.list.fields.pesel'),
          thStyle: 'min-width: 4rem;'
        },
        {
          key: 'documentNumber',
          label: this.$t('views.students.list.fields.document_number'),
          thStyle: 'min-width: 5rem;'
        },
        {
          key: 'documentType',
          label: this.$t('views.students.list.fields.document_type'),
          thStyle: 'min-width: 5rem;'
        },
        {
          key: 'surname',
          label: this.$t('views.students.list.fields.surname'),
          thStyle: 'min-width: 5rem;'
        },
        {
          key: 'name',
          label: this.$t('views.students.list.fields.name'),
          thStyle: ' min-width: 5rem;'
        },
        {
          key: 'otherNames',
          label: this.$t('views.students.list.fields.other_names'),
          thStyle: 'min-width: 5rem'
        },
        {
          key: 'lastExportDate',
          label: this.$t('views.students.list.fields.last_export_date'),
          thStyle: 'min-width: 2rem'
        },
        {
          key: 'status',
          label: this.$t('views.students.list.fields.status'),
          thStyle: 'width: 2rem'
        },
        { 
          key: 'buttons',
          label: ''
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  :deep(.dropdown-menu) {
    overflow: auto;
    max-height: 300px;
  }

  .clicked {
    cursor: pointer;
  }
  :deep(.normal>.checkbox-text) {
    white-space: nowrap;
  }
  .normal > .checkbox-text {
    color: #9d9d9d;
    font-size: .9em;
    font-weight: 400;
    position: relative;
    bottom: .1rem;
    margin-right: .5rem;
  }
  .no-margin {
    :deep(label.pcg-checkbox-container) {
      margin: 0 !important;
    }
    &.form-group {
      margin: 0 !important;
    }
  }

  .users-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  :deep(table.users-table) {
    background-color: $pcg-white;
    border-radius: 15px;
    min-width: 655px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        .import-status span{
            font-weight: 400;
          a {
            color: unset;
          }
        }
        .import-button-group__row{
          display: flex;
          flex-direction: row;
          align-items: center;
          button{
            width: 180px;
          }
        }

        &.td-center {
          text-align: center;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 52px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .sort-by-text {
    color: $pcg-gray;
    font-weight: 400;
    margin-left: .7rem;
    position: relative;
    top: .3rem;
  }

  .sort-by {
    position: relative;
    top: .4rem;
    margin-left: .7rem;
  }

  .filter-users {
    color: $pcg-gray;
    font-weight: 400;
    margin-left: .7rem;
    margin-right: .8rem;
  }

  .my-text-input {
    margin-right: .8rem;
    margin-left: .7rem;
    display: inline-block;
    width: 250px;
    :deep(.pcg-field) {
      height: calc(1.5em + 0.75em + 2px);
    }
  }

  .modal-select {
    position: relative;
    top: .3rem;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
      width: 10rem;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
    :deep(.ls-element .pcg-input){
      height: calc(1.5em + 0.75em + 2px);
    }
  }
  .my-select {
    margin-right: .8rem;
    margin-left: .7rem;
    display: inline-block;
    width: 250px;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    top: .3rem;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .two-disciplines {
    margin-left: .7rem;
    position: relative;
    top: .3rem;
  }

  .my-checkbox {
    position: relative;
    display: inline;
    font-size: $font-size-m;
    :deep(.pcg-checkbox-container) {
      margin-right: 0;
      margin-bottom: 0;         
      top: 2px;
      line-height: 1rem !important;
      font-weight: 400 !important;
      padding-left: 1rem !important;
    }
  }

  .my-text {
    font-weight: 400;
    color: $main-color;
    a {
      color: unset;
    }
  }

  .no-records {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .my-image {
    width: 20px;
    margin-right: .2rem;
    margin-left: .1rem;
    position: relative;
    bottom: .1rem;
  }

  .pagination-summary {
    color: $pcg-gray !important;
    font-weight: 400 !important;
    font-size: $font-size-s !important;
  }

  .my-page-select {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    :deep(.bootstrap-select .dropdown-toggle) {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    :deep(.filter-option-inner) {
      position: relative;
      bottom: .45rem;
    }
  }

  .employee-btn {
    margin-bottom: 1rem;
    position: relative;
    bottom: .5rem;
  }

  .my-input {
    :deep(.pcg-input) {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .required-fields {
    color: $pcg-gray;
    margin-top: 1rem;
    font-weight: 400;
  }

  .edit-role {
    color: $main-active-color;
  }

  .my-header {
    margin-bottom: 2rem;
  }

  .edit-roles {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  .one-role {
    font-weight: 400;
    font-size: $font-size-s;
    color: $pcg-gray;
    position: relative;
    left: .3rem;
  }

  .roles-pen {
    margin-left: .4rem;
    cursor: pointer;
    color: $main-active-color;
  }

  .my-cross {
    color: $pcg-gray;
    position: relative;
    top: .8rem;
    cursor: pointer;
  }

  .user-btn {
    margin-bottom: 1rem;
    position: relative;
    bottom: .5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .delete-cross {
    cursor: pointer;
    color: $pcg-red;
  }

  .role-name {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
    padding-left: 1rem;
    padding-top: .5rem;
  }

    :deep(.tooltip-inner) {
      background: $pcg-the-lightest-gray-background !important;
      color: $pcg-gray !important;
      font-weight: 400;
      padding: .5rem;
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    :deep(.arrow::before) {
      border-top-color: $pcg-light-gray-background !important;
    }

  .no-role {
    color: $pcg-orange;
  }
  .my-input {
    :deep(.pcg-input) {
        height: calc(1.5rem + 0.75rem + 2px)
    }
    :deep(.pcg-input-label) {
        margin-bottom: 0.25rem;
        color: $pcg-gray;
      }
    }
  ::v-deep .check-list {
    margin-top: .1rem;
    margin-left: .1rem;
    width: 400px !important;
    @media (max-width: 890px) {
      width: 300px !important;
    }
    @media (max-width: 620px) {
      width: 250px !important;
    }
    @media (max-width: 575.98px) {
      width: 100% !important;
    }
  }
  :deep(.show-student-changes){
    max-width: 700px;
      .modal-header {
        border-bottom: none;
        .modal-title{
          font-size: 1.71em;
          font-weight: bold;
          line-height: 2rem;
          letter-spacing: .04rem;
          color: $main-color;
          margin-left: .7rem;
          margin-right: 1.5rem;
          text-transform: uppercase;
        }
      }
  }

  .sc-title {
    font-size: 1.71em;
    font-weight: bold;
    line-height: 2rem;
    letter-spacing: .04rem;
    color: $main-color;
    margin-left: .7rem;
    margin-right: 1.5rem;
    text-transform: uppercase;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .sc-title {
      color: $main-color;
    }
    .normal > .checkbox-text {
      color: #9d9d9d;
    }
    .sort-by-text {
      color: $pcg-gray;
    }
    .filter-users {
      color: $pcg-gray;
    }
    .my-select {
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $pcg-gray;
      }
    }
    .my-select-short {
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $pcg-gray;
      }
    }
    .per_page {
      color: $pcg-gray;
    }
    .my-text {
      color: $main-color;
    }
    .no-records {
      color: $pcg-dark-gray;
    }
    .pagination-summary {
      color: $pcg-gray !important;
    }
    .my-page-select {
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $pcg-gray;
      }
    }
    .my-sort {
      color: $pcg-gray;
      :deep(.bootstrap-select .dropdown-toggle) {
        color: $main-color !important;
        border: none;
      }
    }
    .my-pagination {
      :deep(.page-item) {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-error {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .required-fields {
      color: $pcg-gray;
    }
    .roles-pen {
      color: $main-active-color;
    }
    .edit-role {
      color: $main-active-color;
    }
    :deep(.tooltip-inner) {
      background: $pcg-the-lightest-gray !important;
      color: $pcg-gray !important;
      border-color: $pcg-light-gray;
    }

    :deep(.arrow::before){
      border-top-color: $pcg-light-gray !important;
    }
  }

  :deep(.pcg-danger-btn){
    color: #FA4C4D;
    // background: $pcg-white !important;
    border: 1px solid #FA4C4D !important;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .users-table {
      background-color: $pcg-white !important;
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }
      tbody {
        background-color: $pcg-white !important;
        td {
          &.td-center {
            color: $main-color !important;
          }
        }
      }
    }
  }
  .modal-dialog{
    max-width: 700px !important;
    width: auto !important;
  }
</style>

export const views = {
  dev_sessions: {
    dev_sessions_index: {
      action: 'action',
      email: 'Email address',
      logged_in: 'You are already logged in',
      name: 'name',
      password: 'Password',
      roles: 'roles',
      surname: 'surname',
      title: 'Login'
    }
  },
  empty_view: {
    return: 'Return to the home screen',
    subtitle: '',
    text: 'You do not have the appropriate permissions to proceed. Your account is in the system, but you must be approved by the administrator to use the application.',
    title: 'No permissions'
  },
  institutions: {
    cleared: 'The university has been cleared of data',
    created: 'University added successfully',
    dropped: 'The university was successfully deleted',
    list: {
      base_cleaning: 'Database cleaning',
      base_cleaning_confirmation: 'Are you sure you want to clear the database of the university?',
      base_deleting: 'Base deleting',
      base_deleting_confirmation: 'Are you sure you want to delete the database of the university?',
      base_updating: 'Dictionary updating',
      base_updating_confirmation: 'Are you sure you want to update dictionaries of the university?',
      contract_end_date: 'Contract end date',
      cyclical_evaluation: 'Cyclical evaluation',
      fields: {
        name: 'Name',
        sub_domain: 'Subdomain',
        manager_email: 'Manager email',
        identifier: 'Identifier',
        secret: 'Secret',
        origin: 'Origin',
        issuer: 'Issuer',
        end_session_endpoint: 'End session endpoint',
        authorization_endpoint: 'Authorization endpoint',
        token_endpoint: 'Token endpoint',
        user_info_endpoint: 'User info Endpoint',
        scope: "Scope"
      },
      go_into_academy: 'Go to university',
      locked: 'Locked',
      name: 'University name',
      new: 'Add university',
      no_records: 'No universities',
      only_last: 'Only the last one',
      polon_version: 'POL-on version',
      replication_date: 'Replication date',
      subtitle: '',
      title: 'Universities list',
    },
    updated: 'University updated successfully'
  },
  login: {
    header: 'Polon Integration',
    login_btn: 'Login',
  },
  no_subscription_view: {
    return: 'Return to the home screen',
    subtitle: '',
    text: 'Your subscription has expired, please contact your administrator',
    title: 'Your subscription has expired'
  },
  redirect_view: {
    subtitle: '',
    text: 'Failed to redirect. Try again.',
    title: 'Redirection'
  },
  users: {
    list: {
      subtitle: '',
      title: 'Users',
      add_user: 'Add user',
      adding_user: 'Adding user',
      delete_user: 'Delete user',
      delete_user_confirm: 'Deleting user',
      delete_user_confirm_text: 'Are you sure you want to delete the user?',
      delete_user_success: 'User successfully deleted',
      edit_roles: 'Editing roles',
      edit_user: 'Edit user',
      edit_user_failure: 'The user has not been edited',
      edit_user_success: 'User has been successfully edited',
      editing_user: 'Editing user',
      email: 'Email address',
      fields: {
        unit: 'Unit',
        created_at: 'Date of account creation',
        email: 'Email address',
        full_name: 'Name and surname',
        last_login: 'Last login',
        roles: 'User role'
      },
      filter_users: 'Filter users',
      first_name: 'Name',
      no_records: 'No users found',
      no_role: 'No role',
      role: 'Role',
      roles: {
        systemadministrator: 'System administrator',
        systemmanager: 'System manager',
        unitadministrator: 'Unit administrator',
        employee: 'Employee'
      }
    }
  },
  imports: {
    list: {
      fields: {
        full_name: 'User name',
        import_date: 'Imported to system',
        polon_import_start: "Import start",
        polon_import_end: 'Import end',
        import_status: 'Status'
      },
      status: {
        in_progress: 'In progress',
        exported: 'Exported',
        partially_exported: 'Partially exported',
        failed: 'Export Failed'
      }
    },
    exports_list: 'Exports list',
    import_errors: {
      import_errors_export_failure: "Retrieving export errors failed",
      import_errors_export_success: "Export errors have been retrieved"
    }

  },
  sis_imports: {
    list: {
      subtitle: '',
      title: 'Imports',
    }
  },
  integrations: {
    generate_token: 'Generate token',
    integration_data: 'Integration data',
    integration_modal: 'COMPLETING DATA FOR INTEGRATION WITH POL-ON 2.0',
    integration_modal_show: 'DATA FOR INTEGRATION WITH POL-ON 2.0',
    no_token: 'You don\'t have an integration token yet',
    polon: "POL-on 2.0 integration",
    polon_error: 'Incorrect data for integration with POL-on 2.0',
    show_token: 'Show token',
    sis_configuration: 'SIS configuration data',
    token: 'Integration token'
  }
}